<template>
  <div
    class="flex bg-primary-red text-white px-2 xl:px-3 py-1.5 xl:py-3 rounded-sm leading-4 items-center"
    :class="containerClass"
    :style="styleObj"
  >
    <div class="flex items-center w-full justify-between">
      <div class="flex items-center">
        <base-bread-crumb
          v-if="!searchInitiated"
          v-bind="attrs"
          text-property="title"
          @itemSelected="handleSelectedNode({ nodeData: $event })"
        />

        <div v-if="assetsList && assetsList.totalCount">
          <span v-if="searchInitiated">Search Results<span class="ml-2">{{ '( ' + assetsList.totalCount + ' Assets )' }}</span></span>
          <span v-else> {{ '( ' + assetsList.totalCount + ' Assets )' }}</span>
        </div>
        <div v-else-if="searchInitiated">
          No Assets Found
        </div>

      </div>
      <span v-if="!searchInitiated && canExportMetadata">
        <base-svg
          v-if="isExportingMetadata"
          class="w-4 h-4 m-auto py-1 text-white inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        <base-svg
          v-else
          class="h-5 w-5 inline-block cursor-pointer"
          src="icons/export-file.svg"
          :svg-attributes="{
            class: 'h-full w-full',
            title: 'Export Metadata'
          }"
          :title="'Export Metadata'"
          tag="span"
          @click="handleExportMetadata(attrs.items)"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import useStyleGuideDirectory from '@/hooks/styleGuideDirectory.js';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import { useStore } from 'vuex';

export default {
    name: 'BreadCrumbTab',

    components: {
        BaseBreadCrumb: defineAsyncComponent(() => import('@/components/generic-components/BaseBreadCrumb.vue')),
        // BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    inheritAttrs: false,

    props: {
        breadCrumbClass: {
            type: [String, Object, Array],
            default: '',
            description: 'classes applied to breadcrumb'
        },
        containerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to root element'
        },
        styleObj: {
            type: Object,
            default: {},
        }
    },

    setup (props, { attrs }) {
        const { handleSelectedNode } = useStyleGuideDirectory();
        const { assetsList } = useStyleGuideAssets();
        const store = useStore();
        const searchInitiated = computed(() => store.getters['assetsSearch/getSearchInitiated']);
        const canExportMetadata = computed(() => (store.getters['auth/getUserInfo'].groups||[]).includes('Licensing-ADMIN') );

        const isExportingMetadata = ref(false);
        const handleExportMetadata = async (items) => {
            try {
                if (isExportingMetadata.value) return

                isExportingMetadata.value = true
                const __asset = {};
                if (items.length === 1) {
                    __asset.mrlc_attr_tier_1 = items[0].title;
                } else {
                    const slicedItems = items.slice(-1)[0];
                    Object.entries(slicedItems).forEach(([key, value]) => {
                        if (key.startsWith('mrlc_attr_tier_')) {
                            __asset[key] = value;
                        }
                    });
                }

                let resp = await store.dispatch('assets/exportMetadata', { params: __asset });
                const windowOpened = window.open(resp?.presignedUrl, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                }

            } catch (err) {
                console.error(err);
            } finally {
                isExportingMetadata.value = false
            }
        };

        return {
            attrs,
            handleSelectedNode,
            searchInitiated,
            assetsList,
            handleExportMetadata,
            isExportingMetadata,
            canExportMetadata
        };
    }
};
</script>
